import React, {useEffect} from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import PageLink from '~containers/page-link';
import {firebaseAnalytics} from '~utils/firebase';

// page-slug
const About = props => {
  useEffect(() => {
    firebaseAnalytics.logEvent('About_page_visited');
  }, []);

  const {
    params: {pageAbout},
  } = props;
  return (
    <Layout>
      <Seo title='About' />
      <PageLink link={pageAbout} isAbout />
    </Layout>
  );
};

export default About;
